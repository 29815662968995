<template>
  <app-form-view
    multipart
    app="contact"
    model="contact"
    api-url="contact/contact/"
    :title="$t('menu.contact')"
    :state="formData.state"
    :stateItems="stateItems"
    :prepare-data="prepareData"
    v-model="formData"
    :approveItems="approveItems"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" class="d-flex">
          <app-input
            name="profile"
            type="picture"
            :label="$t('fields.profile')"
            :view="view"
            :binds="{
              width: '200',
              height: '200'
            }"
            :view-binds="{
              width: '200',
              height: '200'
            }"
            v-model="formData.profile_image"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <app-input
            name="contactType"
            type="radio"
            rules="required"
            :label="$t('fields.contactType')"
            :view="view"
            :items="[
              { label: $t('label.individual'), value: 'individual' },
              { label: $t('label.company'), value: 'company' }
            ]"
            v-model="formData.contact_type"
            @input="onContactChanges"
          >
            <template v-slot:view-value="{ value }">
              {{ $t(`label.${value}`) }}
            </template>
          </app-input>
          <app-input
            v-if="isSentToApprove"
            name="approve_contact_type"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact_type"
          />
        </v-col>
        <v-col cols="12" md="6" v-if="formData.contact_type === 'individual'">
          <app-input
            name="employee"
            type="checkbox"
            :label="$t('fields.employee')"
            :view="view"
            v-model="formData.is_employee"
            v-if="formData.contact_type === 'individual'"
          />
          <app-input
            v-if="isSentToApprove && formData.contact_type === 'individual'"
            name="approve_employee"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_employee"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="nationalId"
            rules="required|numeric|digits:13"
            :label="$t('fields.taxID')"
            :view="view"
            v-model="formData.national_id_no"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveNationalId"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_national_id_no"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="prefix"
            type="select"
            rules="required"
            :label="$t('fields.prefix')"
            :items="prefixItems"
            :view="view"
            :binds="{
              laoding: prefixLoading,
              itemText: 'name',
              itemValue: 'id',
              returnObject: true
            }"
            v-model="formData.prefix_id"
          >
            <template v-slot:view-value="{ value }">
              {{ value.name }}
            </template>
          </app-input>
          <app-input
            v-if="isSentToApprove"
            name="approvePrefix"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_prefix"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="firstName"
            rules="required"
            :label="firstNameText"
            :view="view"
            v-model="formData.first_name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveFirstName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_first_name"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="app-input--approve-wrapper"
          v-if="formData.contact_type === 'individual'"
        >
          <app-input
            name="lastName"
            rules="required"
            :label="$t('fields.lastName')"
            :view="view"
            v-model="formData.last_name"
            v-if="formData.contact_type === 'individual'"
          />
          <app-input
            v-if="isSentToApprove && formData.contact_type === 'individual'"
            name="approveLastName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_last_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="app-input--approve-wrapper"
          v-if="formData.contact_type === 'individual'"
        >
          <app-input
            name="birthDate"
            type="datepicker"
            :label="$t('fields.birthDate')"
            :view="view"
            v-model="formData.date_of_birth"
            v-if="formData.contact_type === 'individual'"
          />

          <app-input
            v-if="isSentToApprove && formData.contact_type === 'individual'"
            name="approveDateOfBirth"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_date_of_birth"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="customer_name"
            type="select-server"
            rules="required"
            :label="$t('fields.customerCompany')"
            :view="view"
            :binds="{
              apiUrl: 'customer-company/customer_company/?active=true'
            }"
            v-model="formData.customer_company_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveCustomerCompany"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_customer_company"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="department"
            type="select-server"
            :label="$t('fields.department')"
            :view="view"
            :binds="{
              apiUrl: 'role/role/?active=true'
            }"
            v-model="formData.role_id"
            v-if="formData.is_employee"
          />
          <app-input
            v-if="isSentToApprove && formData.is_employee"
            name="approveDepartment"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_department"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="cost"
            type="number"
            :label="$t('fields.cost')"
            :view="view"
            v-model="formData.cost"
            v-if="formData.is_employee"
          />
          <app-input
            v-if="isSentToApprove && formData.is_employee"
            name="approveCost"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_cost"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>

      <v-card>
        <v-tabs background-color="primary" dark v-model="tab">
          <v-tab
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            {{ $t('menu.region') }}
          </v-tab>
          <v-tab
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            Business
          </v-tab>
          <v-tab
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            Business Unit
          </v-tab>
          <v-tab
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            Risk Profile
          </v-tab>
          <v-tab
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            Activity Profile
          </v-tab>
          <v-tab
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            Require Document
          </v-tab>
          <v-tab v-if="formData.contact_type === 'company'">
            Stakeholder Document
          </v-tab>
          <v-tab v-if="formData.contact_type === 'company'">
            Director Document
          </v-tab>
          <v-tab v-if="formData.contact_type === 'company'">
            Director Term
          </v-tab>
          <v-tab v-if="formData.contact_type === 'company'">
            Contributor Document
          </v-tab>
          <v-tab v-if="formData.contact_type === 'company'"> Branch </v-tab>
          <v-tab v-if="formData.contact_type === 'company'">
            ภ.พ.20 Document
          </v-tab>
          <v-tab v-if="formData.contact_type === 'company'">
            Company Other Document
          </v-tab>
          <v-tab v-if="formData.contact_type === 'company'">
            Company Other License
          </v-tab>
          <v-tab
            v-if="mode !== 'create' && formData.contact_type === 'individual'"
            >Personal Document</v-tab
          >
          <v-tab v-if="mode !== 'create'">บอจ.5 Document</v-tab>
          <v-tab
            v-if="mode !== 'create' && formData.contact_type === 'individual'"
            >หนังสือรับรองบริษัท Document</v-tab
          >
          <v-tab v-if="mode !== 'create'">Address</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Region Tab -->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="table"
                  app="contact"
                  model="contactregion"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="contactRegionheaders"
                  :serverItems="ContactRegionItems"
                  :clientItems="ContactRegionItems"
                  :loading="loading"
                  :server-items-length="ContactRegionItemsLength"
                  v-model="ContactRegionSelected"
                  @server="getContactRegionItems"
                  @client="getContactRegionClientItems"
                  @delete="onDeleteContactRegionItem"
                  @edit="onEditContactRegion"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>
                        {{ $t('menu.region') }}
                      </v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogContactRegion" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                          <slot name="actions">
                            <v-btn
                              v-show="mode === 'edit'"
                              dark
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              New Item
                            </v-btn>
                            <v-btn
                              v-show="
                                ContactRegionSelected.length && mode === 'edit'
                              "
                              color="error"
                              @click="
                                onDeleteContactRegionItem(ContactRegionSelected)
                              "
                            >
                              {{ $t('btn.delete') }}
                            </v-btn>
                          </slot>
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedContactRegionItem.region_id"
                                    name="regionName"
                                    type="select-server"
                                    :label="$t('fields.regionName')"
                                    :view="view"
                                    :binds="{
                                      apiUrl: `region/region/?active=true&state=approved`
                                    }"
                                    @input="getDivisionItem"
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="
                                      editedContactRegionItem.division_id
                                    "
                                    name="divisionName"
                                    type="select-server"
                                    :label="$t('fields.divisionName')"
                                    :view="view"
                                    :binds="{
                                      apiUrl: divisionUrl
                                    }"
                                    :disabled="
                                      !Boolean(
                                        editedContactRegionItem.region_id
                                      )
                                    "
                                    @input="getSubDivisionItem"
                                  ></app-input>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="
                                      editedContactRegionItem.sub_division_id
                                    "
                                    name="subDivisionName"
                                    type="select-server"
                                    :label="$t('fields.subDivisionName')"
                                    :view="view"
                                    :binds="{
                                      apiUrl: subDivisionUrl
                                    }"
                                    :disabled="
                                      !Boolean(
                                        editedContactRegionItem.division_id
                                      )
                                    "
                                  ></app-input>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeContactRegion"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveContactRegion"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company business tab -->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  app="contact"
                  model="contactbusiness"
                  :headers="businessHeaders"
                  :serverItems="businessItems"
                  :clientItems="businessItems"
                  :loading="loading"
                  :server-items-length="businessServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getBusinessItems"
                  @delete="onDeleteBusinessItem"
                  @client="getBusinessClientItems"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Business</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog v-model="dialogBusiness" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteBusinessItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedItem.industry_id"
                                        name="industry"
                                        type="select-server"
                                        :label="$t('fields.industry')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'doc-template/industry/?active=true'
                                        }"
                                      ></app-input>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedItem.business_cate_id"
                                        name="category"
                                        type="select-server"
                                        :label="$t('fields.category')"
                                        :view="view"
                                        :binds="{
                                          apiUrl: editedItem.industry_id
                                            ? `doc-template/category/?active=true&industry_id=${editedItem.industry_id.id}`
                                            : 'doc-template/category/?active=true'
                                        }"
                                      ></app-input>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedItem.business_type_id"
                                        name="buztype"
                                        type="select-server"
                                        :label="$t('fields.businessType')"
                                        :view="view"
                                        :binds="{
                                          apiUrl: editedItem.business_cate_id
                                            ? `doc-template/buztype/?active=true&business_cate_id=${editedItem.business_cate_id.id}`
                                            : 'doc-template/buztype/?active=true'
                                        }"
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeBusiness"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveBusiness"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company business Unit tab -->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  app="contact"
                  model="contactbusinessunit"
                  :headers="businessUnitHeaders"
                  :serverItems="businessUnitItems"
                  :clientItems="businessUnitItems"
                  :loading="loading"
                  :server-items-length="businessUnitServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getBusinessUnitItems"
                  @delete="onDeleteBusinessUnitItem"
                  @client="getBusinessUnitClientItems"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Business Unit</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogBusinessUnit"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteBusinessUnitItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedItem.businessUnit_id"
                                        name="BusinessUnit"
                                        type="select-server"
                                        :label="$t('fields.businessUnit')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'contact/business-unit/?active=true&businessunitbusinesstype__is_primary=true'
                                        }"
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeBusinessUnit"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveBusinessUnit"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company Risk Profile tab -->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  app="contact"
                  model="contactriskprofile"
                  :headers="riskProfileHeaders"
                  :serverItems="riskProfileItems"
                  :clientItems="riskProfileItems"
                  :loading="loading"
                  :server-items-length="riskProfileServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getRiskProfileItems"
                  @delete="onDeleteRiskProfileItem"
                  @client="getRiskProfileClientItems"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Riks Profile</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRiskProfile"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteRiskProfileItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="
                                          editedItem.risk_profile_template_header_id
                                        "
                                        name="RiskProfile"
                                        type="select-server"
                                        :label="$t('fields.riskProfile')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'risk/risk-profile-template-header/?active=true'
                                        }"
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeRiskProfile"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveRiskProfile"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company Activity Profile tab -->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  app="contact"
                  model="contactactivityprofile"
                  :headers="activityProfileHeaders"
                  :serverItems="activityProfileItems"
                  :clientItems="activityProfileItems"
                  :loading="loading"
                  :server-items-length="activityProfileServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getActivityProfileItems"
                  @delete="onDeleteActivityProfileItem"
                  @client="getActivityProfileClientItems"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Activity Profile</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogActivityProfile"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteActivityProfileItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedItem.activity_profile_id"
                                        name="ActivityProfile"
                                        type="select-server"
                                        :label="$t('fields.activityProfile')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'activity/activity-profile-header/?active=true'
                                        }"
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeActivityProfile"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveActivityProfile"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company require document tab -->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  hide-delete
                  app="contact"
                  model="personaldocument"
                  :headers="requireDocumentHeaders"
                  :serverItems="requireDocumentItems"
                  :clientItems="requireDocumentItems"
                  :loading="requireDocumentLoading"
                  :server-items-length="requireDocumentItemsLength"
                  :bind="{ showSelect: false }"
                  @server="getRequireDocumentItems"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company stakeholder document tab -->
          <v-tab-item v-if="formData.contact_type === 'company'">
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-delete
                    app="contact"
                    model="personaldocument"
                    :headers="stakeholderDocHeaders"
                    :search="search"
                    :serverItems="stakeholderDocItems"
                    :clientItems="stakeholderDocItems"
                    subItemKey="fields"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="serverItemsLength"
                    @server="getStakeholderDocItems"
                    show-expand
                    item-key="id"
                    :isExpand="isExpand"
                    @edit="onEditStakeholderDoc"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >Stakeholder Document</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialogStakeHolder"
                              max-width="1000px"
                            >
                              <template>
                                <app-table
                                  server-side
                                  app="special_doc"
                                  model="stakeholderdocumentheader"
                                  :headers="stakeholderHeaders"
                                  :search="search"
                                  :serverItems="stakeholderItems"
                                  :clientItems="stakeholderItems"
                                  :loading="loading"
                                  hide-edit
                                  hide-delete
                                  v-bind="binds"
                                  v-model="selected"
                                  itemKey="code"
                                  :server-items-length="serverItemsLength"
                                  v-if="mode !== 'create'"
                                >
                                  <template v-slot:top>
                                    <div class="app-list-view">
                                      <div class="app-list-view-header">
                                        <v-toolbar flat>
                                          <v-toolbar-title>{{
                                            $t('label.stakeholder')
                                          }}</v-toolbar-title>
                                          <v-divider
                                            class="mx-4"
                                            inset
                                            vertical
                                          ></v-divider>
                                          <v-spacer></v-spacer>
                                        </v-toolbar>
                                      </div>
                                    </div>
                                  </template>
                                </app-table>
                              </template>
                              <v-card>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeStakeHolder"
                                  >
                                    close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company director document tab -->
          <v-tab-item v-if="formData.contact_type === 'company'">
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-delete
                    app="contact"
                    model="personaldocument"
                    :headers="directorDocHeaders"
                    :search="search"
                    :serverItems="directorDocItems"
                    :clientItems="directorDocItems"
                    subItemKey="fields"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="serverItemsLength"
                    @server="getDirectorDocItems"
                    show-expand
                    item-key="id"
                    :isExpand="isExpand"
                    @edit="onEditDirectorDoc"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>Director Document</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog
                              v-model="dialogDirectorDoc"
                              max-width="1000px"
                            >
                              <template>
                                <app-table
                                  server-side
                                  app="special_doc"
                                  model="directordocument"
                                  :headers="directorHeaders"
                                  :search="search"
                                  :serverItems="directorItems"
                                  :clientItems="directorItems"
                                  :loading="loading"
                                  hide-edit
                                  hide-delete
                                  v-bind="binds"
                                  v-model="selected"
                                  itemKey="code"
                                  :server-items-length="serverItemsLength"
                                  v-if="mode !== 'create'"
                                >
                                  <template v-slot:top>
                                    <div class="app-list-view">
                                      <div class="app-list-view-header">
                                        <v-toolbar flat>
                                          <v-toolbar-title>{{
                                            $t('label.committee')
                                          }}</v-toolbar-title>
                                          <v-divider
                                            class="mx-4"
                                            inset
                                            vertical
                                          ></v-divider>
                                          <v-spacer></v-spacer>
                                        </v-toolbar>
                                      </div>
                                    </div>
                                  </template>
                                </app-table>
                                <v-card>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeDirectorDoc"
                                    >
                                      close
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </template>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company director term tab -->
          <v-tab-item v-if="formData.contact_type === 'company'">
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    app="contact"
                    model="contactdirectorterm"
                    :headers="directorTermHeaders"
                    :search="search"
                    :serverItems="directorTermItems"
                    :clientItems="directorTermItems"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selected"
                    :server-items-length="serverItemsLength"
                    @server="getDirectorTermItems"
                    @edit="onEditDirectorTerm"
                    @delete="onDeleteDirectorTermItem"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title> Term </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog
                              v-model="dialogDirectorTerm"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="selected && mode === 'edit'"
                                    color="error"
                                    @click="onDeleteDirectorTermItem(selected)"
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          name="term"
                                          :label="$t('fields.term')"
                                          :view="view"
                                          v-model="editedItem.term"
                                        >
                                        </app-input>
                                        <app-input
                                          v-model="editedItem.start_term_date"
                                          name="start_term_date"
                                          :label="$t('fields.startTermDate')"
                                          :view="view"
                                          type="datepicker"
                                        ></app-input>
                                        <app-input
                                          v-model="editedItem.end_term_date"
                                          name="end_term_date"
                                          :label="$t('fields.endTermDate')"
                                          :view="view"
                                          type="datepicker"
                                        ></app-input>
                                        <app-input
                                          v-model="editedItem.image"
                                          name="image"
                                          :label="$t('fields.image')"
                                          :view="view"
                                          type="picture"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDirectorTerm"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveTerm"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company Contributor document tab -->
          <v-tab-item v-if="formData.contact_type === 'company'">
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-edit
                    hide-delete
                    app="contact"
                    model="personaldocument"
                    :headers="contributorDocHeaders"
                    :search="search"
                    :serverItems="contributorDocItems"
                    :clientItems="contributorDocItems"
                    subItemKey="fields"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="serverItemsLength"
                    @server="getContributorDocItems"
                    show-expand
                    item-key="id"
                    :isExpand="isExpand"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >Contributor Document</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- สาขา/Branch -->
          <v-tab-item v-if="formData.contact_type === 'company'">
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    app="contact"
                    model="contactbranch"
                    :headers="contactbranchHeaders"
                    :search="search"
                    :serverItems="contactbranchItems"
                    :clientItems="contactbranchItems"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="contactBranchserverItemsLength"
                    @server="getContactbranchItems"
                    @client="getClientContactbranchItems"
                    @delete="onDeleteContectBranchItem"
                    @edit="onEditBranchItem"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>Branch</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <!-- Dialog Audit Wizard Pop -->
                            <v-dialog v-model="dialogBranch" max-width="1500">
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                    @click="openDialogBranch"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="
                                      selectedHeader.length && mode === 'edit'
                                    "
                                    color="error"
                                    @click="
                                      onDeleteContectBranchItem(selectedHeader)
                                    "
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card flat>
                                <v-card-text class="pt-4">
                                  <v-toolbar dense color="primary white--text">
                                    <v-toolbar-title>
                                      {{ $t('menu.createBranch') }}
                                    </v-toolbar-title>
                                  </v-toolbar>
                                  <app-form
                                    app="contact"
                                    model="contactbranch"
                                    api-url="contact/contact-branch/"
                                    :title="$t('menu.contactBranch')"
                                    v-model="formData"
                                    :prepare-data="prepareData"
                                    :state="formData.state"
                                    hideSave
                                    hideCancel
                                  >
                                    <template>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="establishment_name"
                                            rules="required"
                                            type="text"
                                            :label="
                                              $t('fields.establishment_name')
                                            "
                                            v-model="
                                              editedBranchItem.establishment_name
                                            "
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="contact_id"
                                            rules="required"
                                            type="select-server"
                                            :label="$t('fields.contact')"
                                            :binds="{
                                              apiUrl: contactUrl
                                            }"
                                            v-model="
                                              editedBranchItem.contact_id
                                            "
                                            disabled
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="internal_branch_code"
                                            rules="required"
                                            type="text"
                                            :label="
                                              $t('fields.internal_branch_code')
                                            "
                                            v-model="
                                              editedBranchItem.internal_branch_code
                                            "
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="branch_code"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.branch_code')"
                                            v-model="
                                              editedBranchItem.branch_code
                                            "
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="name"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.branchName')"
                                            v-model="editedBranchItem.name"
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="note"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.note')"
                                            v-model="editedBranchItem.note"
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="building"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.building')"
                                            v-model="editedBranchItem.building"
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="room_number"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.room_no')"
                                            v-model="
                                              editedBranchItem.room_number
                                            "
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="floor"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.floor')"
                                            v-model="editedBranchItem.floor"
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="village"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.village')"
                                            v-model="editedBranchItem.village"
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="house_no"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.house_no')"
                                            v-model="editedBranchItem.house_no"
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="moo_no"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.moo_no')"
                                            v-model="editedBranchItem.moo_no"
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="alley"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.alley')"
                                            v-model="editedBranchItem.alley"
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="road"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.road')"
                                            v-model="editedBranchItem.road"
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            v-model="
                                              editedBranchItem.country_id
                                            "
                                            name="country"
                                            type="select-server"
                                            :label="$t('fields.country')"
                                            :view="view"
                                            :binds="{
                                              apiUrl: 'contact/rescountry/'
                                            }"
                                            @input="getStateItem"
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            v-model="editedBranchItem.state_id"
                                            name="state"
                                            type="select-server"
                                            :label="$t('fields.state')"
                                            :view="view"
                                            :binds="{
                                              apiUrl: stateUrl
                                            }"
                                            @input="setCityItems"
                                          >
                                          </app-input>
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            v-model="editedBranchItem.city_id"
                                            name="city"
                                            type="select"
                                            :label="$t('fields.city')"
                                            :view="view"
                                            :binds="{
                                              loading: cityLoading,
                                              itemText: 'name',
                                              itemValue: 'id',
                                              returnObject: true
                                            }"
                                            :items="cityItems"
                                            @input="getSubdistrict"
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            v-model="
                                              editedBranchItem.sub_district_id
                                            "
                                            name="sub district"
                                            type="select-server"
                                            :label="$t('fields.subDistrict')"
                                            :view="view"
                                            :binds="{
                                              apiUrl: subdistrictUrl
                                            }"
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="zip_code"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.zipCode')"
                                            v-model="editedBranchItem.zip_code"
                                          />
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            name="tel"
                                            rules="required"
                                            type="text"
                                            :label="$t('fields.tel')"
                                            v-model="editedBranchItem.tel"
                                          />
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </app-form>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDialogBranch"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    @click="saveContactBranch"
                                  >
                                    {{ $t('btn.save') }}
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Company ภ.พ.20 document tab -->
          <v-tab-item v-if="formData.contact_type === 'company'">
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-edit
                    hide-delete
                    app="contact"
                    model="personaldocument"
                    :headers="taxRegistrationDocHeaders"
                    :search="search"
                    :serverItems="taxRegistrationDocItems"
                    :clientItems="taxRegistrationDocItems"
                    subItemKey="fields"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="serverItemsLength"
                    @server="getTaxRegistrationDocItems"
                    show-expand
                    item-key="id"
                    :isExpand="isExpand"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >Contributor Document</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Individual company other document tab -->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-edit
                    hide-delete
                    app="contact"
                    model="personaldocument"
                    :headers="companyOtherDocHeaders"
                    :search="search"
                    :serverItems="companyOtherDocItems"
                    :clientItems="companyOtherDocItems"
                    subItemKey="fields"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="serverItemsLength"
                    @server="getCompanyOtherDocItems"
                    show-expand
                    item-key="id"
                    :isExpand="isExpand"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>Other Document</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Company other license tab -->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'company'"
          >
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-edit
                    hide-delete
                    app="contact"
                    model="personaldocument"
                    :headers="companyOtherLicenseHeaders"
                    :search="search"
                    :serverItems="companyOtherLicenseItems"
                    :clientItems="companyOtherLicenseItems"
                    subItemKey="fields"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="serverItemsLength"
                    @server="getCompanyOtherLicenseItems"
                    show-expand
                    item-key="id"
                    :isExpand="isExpand"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >Other License Document</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Individual personal document tab -->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'individual'"
          >
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-edit
                    hide-delete
                    app="contact"
                    model="personaldocument"
                    :headers="personalDocHeaders"
                    :search="search"
                    :serverItems="personalDocItems"
                    :clientItems="personalDocItems"
                    subItemKey="fields"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="serverItemsLength"
                    @server="getPersonalDocItems"
                    @client="getPersonalDocClientItems"
                    show-expand
                    item-key="id"
                    :isExpand="isExpand"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>Personal Document</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Both บอจ.5 tab-->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-edit
                    hide-delete
                    app="special_document"
                    model="stakeholderdocumentheader"
                    :headers="individualDocHeaders"
                    :search="search"
                    :serverItems="individualItems"
                    :clientItems="individualItems"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="serverItemsLength"
                    @server="getIndividualStakeholderDocItems"
                    @client="getIndividualStakeholderDocClientItems"
                    item-key="id"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>ถือหุ้น</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Individual หนังสือรับรองบริษัท tab-->
          <v-tab-item
            v-if="mode !== 'create' && formData.contact_type === 'individual'"
          >
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-edit
                    hide-delete
                    app="special_document"
                    model="directordocument"
                    :headers="individualDocHeaders"
                    :search="search"
                    :serverItems="individualItems"
                    :clientItems="individualItems"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedHeader"
                    :server-items-length="serverItemsLength"
                    @server="getIndividualDirectorDocItems"
                    item-key="id"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >หนังสือรับรองบริษัท</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Both address tab -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    app="contact"
                    model="personaldocument"
                    :headers="addressHeaders"
                    :search="search"
                    :serverItems="addressItems"
                    :clientItems="addressItems"
                    :loading="loading"
                    :server-items-length="serverItemsLength"
                    v-bind="binds"
                    v-model="selected"
                    @server="getAddressItems"
                    @client="getAddressClientItems"
                    @edit="onEdit"
                    @delete="onDelete"
                    itemKey="name"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>Address</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog v-model="dialogAddress" max-width="500px">
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="selected.length && mode === 'edit'"
                                    color="error"
                                    @click="onDelete(selected)"
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col>
                                        <app-input
                                          v-model="editedItem.name"
                                          name="name"
                                          :label="$t('fields.name')"
                                          :view="view"
                                        >
                                        </app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col>
                                        <app-input
                                          v-model="editedItem.street"
                                          name="street"
                                          :label="$t('fields.street')"
                                          :view="view"
                                        >
                                        </app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col>
                                        <app-input
                                          v-model="editedItem.country_id"
                                          name="country"
                                          type="select-server"
                                          :label="$t('fields.country')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: 'contact/rescountry/'
                                          }"
                                          @input="getStateItem"
                                        >
                                        </app-input>
                                      </v-col>
                                      <v-col>
                                        <app-input
                                          v-model="editedItem.state_id"
                                          name="state"
                                          type="select-server"
                                          :label="$t('fields.state')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: stateUrl
                                          }"
                                          @input="getCityItem"
                                        >
                                        </app-input>
                                      </v-col>
                                      <v-col>
                                        <app-input
                                          v-model="editedItem.city_id"
                                          name="city"
                                          type="select-server"
                                          :label="$t('fields.city')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: cityUrl
                                          }"
                                          @input="getSubdistrict"
                                        >
                                        </app-input>
                                      </v-col>
                                      <v-col>
                                        <app-input
                                          v-model="editedItem.sub_district_id"
                                          name="sub district"
                                          type="select-server"
                                          :label="$t('fields.subDistrict')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: subdistrictUrl
                                          }"
                                        >
                                        </app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeAddress"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table></template
                >
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput'
import AppTable from '@components/AppTable'
import { objFormData } from '@utils/function'

export default {
  name: 'contactDraft',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  props: {
    binds: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    approveItems() {
      let additionalItems = []

      if (this.formData.contact_type === 'individual') {
        additionalItems = [
          'approve_last_name',
          'approve_date_of_birth',
          'approve_employee'
        ]
      }

      return [...this.baseApproveItems, ...additionalItems]
    },
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    fullName() {
      if (this.formData.contact_type == 'company') {
        return [this.formData.prefix_id, this.formData.first_name].every(
          (value) => value
        )
          ? `${this.formData.prefix_id.name} ${this.formData.first_name}`
          : '&nbsp;'
      } else {
        return [
          this.formData.prefix_id,
          this.formData.first_name,
          this.formData.last_name
        ].every((value) => value)
          ? `${this.formData.prefix_id.name} ${this.formData.first_name} ${this.formData.last_name}`
          : '&nbsp;'
      }
    },
    companyOtherDocHeaders() {
      return [
        { text: this.$t('fields.name'), value: 'document_template_id.name' },
        {
          text: this.$t('fields.type'),
          value: 'document_template_id.type'
        },
        {
          text: this.$t('fields.tags'),
          value: 'document_template_id.tags'
        }
      ]
    },
    companyOtherLicenseHeaders() {
      return [
        { text: this.$t('fields.name'), value: 'document_template_id.name' },
        {
          text: this.$t('fields.type'),
          value: 'document_template_id.type'
        },
        {
          text: this.$t('fields.tags'),
          value: 'document_template_id.tags'
        }
      ]
    },
    personalDocHeaders() {
      return [
        { text: this.$t('fields.name'), value: 'document_template_id.name' },
        {
          text: this.$t('fields.type'),
          value: 'document_template_id.type',
          hideFilter: true
        },
        {
          text: this.$t('fields.tags'),
          value: 'document_template_id.tags'
        }
      ]
    },
    stakeholderDocHeaders() {
      return [
        { text: this.$t('fields.name'), value: 'document_template_id.name' },
        {
          text: this.$t('fields.type'),
          value: 'document_template_id.type'
        },
        {
          text: this.$t('fields.tags'),
          value: 'document_template_id.tags'
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    individualDocHeaders() {
      return [
        {
          text: this.$t('fields.companyName'),
          value: 'personal_id.fields.name'
        },
        {
          text: this.$t('fields.taxID'),
          value: 'personal_id.fields.company id'
        }
      ]
    },
    directorDocHeaders() {
      return [
        { text: this.$t('fields.name'), value: 'document_template_id.name' },
        {
          text: this.$t('fields.type'),
          value: 'document_template_id.type'
        },
        {
          text: this.$t('fields.tags'),
          value: 'document_template_id.tags'
        },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    },
    directorTermHeaders() {
      return [
        { text: this.$t('fields.term'), value: 'term' },
        { text: this.$t('fields.startTermDate'), value: 'start_term_date' },
        { text: this.$t('fields.endTermDate'), value: 'end_term_date' },
        { text: this.$t('fields.action'), value: 'actions', sortable: false }
        // {text:'image',value:'image'}
      ]
    },
    contributorDocHeaders() {
      return [
        { text: this.$t('fields.name'), value: 'document_template_id.name' },
        {
          text: this.$t('fields.type'),
          value: 'document_template_id.type'
        },
        {
          text: this.$t('fields.tags'),
          value: 'document_template_id.tags'
        }
      ]
    },
    taxRegistrationDocHeaders() {
      return [
        { text: this.$t('fields.name'), value: 'document_template_id.name' },
        {
          text: this.$t('fields.type'),
          value: 'document_template_id.type'
        },
        {
          text: this.$t('fields.tags'),
          value: 'document_template_id.tags'
        }
      ]
    },
    addressHeaders() {
      return [
        { text: this.$t('fields.name'), value: 'name' },
        {
          text: this.$t('fields.address'),
          value: 'street'
        },
        {
          text: this.$t('fields.country'),
          value: 'country_id.name'
        },
        {
          text: this.$t('fields.state'),
          value: 'state_id.name'
        },
        {
          text: this.$t('fields.city'),
          value: 'city_id.name'
        },
        {
          text: this.$t('fields.subDistrict'),
          value: 'sub_district_id.name'
        },
        {
          text: this.$t('fields.zipCode'),
          value: 'sub_district_id.zip_code'
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    businessHeaders() {
      return [
        { text: this.$t('fields.businessIndustry'), value: 'industry_id.name' },
        {
          text: this.$t('fields.businessCategory'),
          value: 'business_cate_id.name'
        },
        {
          text: this.$t('fields.businessType'),
          value: 'business_type_id.name'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    businessUnitHeaders() {
      return [
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit_id.name'
        },
        { text: this.$t('fields.businessIndustry'), value: 'industry.name' },
        {
          text: this.$t('fields.businessCategory'),
          value: 'business_cate_id.name'
        },
        {
          text: this.$t('fields.businessType'),
          value: 'business_type_id.name'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    riskProfileHeaders() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'risk_profile_template_header_id.name'
        },
        {
          text: this.$t('fields.description'),
          value: 'risk_profile_template_header_id.description'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    activityProfileHeaders() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'activity_profile_id.name'
        },
        {
          text: this.$t('fields.description'),
          value: 'activity_profile_id.description'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    requireDocumentHeaders() {
      return [
        { text: this.$t('fields.document'), value: 'document' },
        { text: this.$t('fields.done'), value: 'done', check: true }
      ]
    },
    stakeholderHeaders() {
      return [
        { text: this.$t('fields.seq'), value: 'seq' },
        { text: this.$t('fields.prefix'), value: 'prefix_id.name' },
        { text: this.$t('fields.firstName'), value: 'first_name' },
        { text: this.$t('fields.lastName'), value: 'last_name' },
        { text: this.$t('fields.idNo'), value: 'id_card' },
        { text: this.$t('fields.stock_type'), value: 'stock_type' },
        { text: this.$t('fields.total_stock'), value: 'total_stock' },
        { text: this.$t('fields.stock_price'), value: 'stock_price' },
        {
          text: this.$t('fields.stock_number_from'),
          value: 'stock_number_from'
        },
        { text: this.$t('fields.stock_number_to'), value: 'stock_number_to' },
        {
          text: this.$t('fields.stock_number_date'),
          value: 'stock_number_date'
        },
        {
          text: this.$t('fields.stock_owner_date_from'),
          value: 'stock_owner_date_from'
        },
        {
          text: this.$t('fields.stock_owner_date_to'),
          value: 'stock_owner_date_to'
        },
        { text: this.$t('fields.stock_percentage'), value: 'stock_percentage' },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    contactbranchHeaders() {
      return [
        {
          text: this.$t('fields.internal_branch_code'),
          value: 'internal_branch_code'
        },
        {
          text: this.$t('fields.branch_code'),
          value: 'branch_code'
        },
        {
          text: this.$t('fields.branchName'),
          value: 'name'
        },
        {
          text: this.$t('fields.contact'),
          value: 'contact_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.establishment_name'),
          value: 'establishment_name'
        },
        {
          text: this.$t('fields.city'),
          value: 'city_id.name'
        },
        {
          text: this.$t('fields.state'),
          value: 'state_id.name'
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    directorHeaders() {
      return [
        { text: this.$t('fields.contact'), value: 'contact_id.name' },
        { text: this.$t('fields.prefix'), value: 'prefix_id.name' },
        {
          text: this.$t('fields.name'),
          value: 'first_name'
        },
        {
          text: this.$t('fields.lastName'),
          value: 'last_name'
        },
        {
          text: this.$t('fields.id'),
          value: 'id_card'
        }
      ]
    },
    contactRegionheaders() {
      return [
        {
          text: this.$t('fields.regionName'),
          value: 'region_id.name'
        },
        {
          text: this.$t('fields.divisionName'),
          value: 'division_id.name'
        },
        {
          text: this.$t('fields.subDivisionName'),
          value: 'sub_division_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  data() {
    return {
      tab: null,
      isExpand: true,
      prefixLoading: true,
      cityLoading: false,
      prefixItems: [],
      cityItems: [],
      formData: {
        state: 'draft'
      },
      baseApproveItems: [
        'approve_contact_type',
        'approve_national_id_no',
        'approve_prefix',
        'approve_first_name'
      ],

      directorItems: [],
      stakeholderItems: [],
      personalDocItems: [],
      companyOtherDocItems: [],
      companyOtherLicenseItems: [],
      subPersonalDocItems: [],
      stakeholderDocItems: [],
      individualItems: [],
      directorDocItems: [],
      directorTermItems: [],
      contributorDocItems: [],
      taxRegistrationDocItems: [],
      addressItems: [],
      loading: false,
      serverItemsLength: 0,
      search: '',
      selectedHeader: [],
      dialog: false,
      dialogBusiness: false,
      dialogBusinessUnit: false,
      dialogRiskProfile: false,
      dialogActivityProfile: false,
      dialogStakeHolder: false,
      dialogDirectorDoc: false,
      dialogDirectorTerm: false,
      dialogAddress: false,
      selected: [],
      editedItem: {},
      stateItem: {
        label: null,
        value: null
      },
      cityItem: {
        label: null,
        value: null
      },
      subdistrictItem: {
        label: null,
        value: null
      },
      stateUrl: null,
      cityUrl: null,
      subdistrictUrl: null,
      savedItem: {},
      apiMethod: 'post',
      editId: null,
      businessServerItemsLength: 0,
      businessItems: [],
      businessUnitServerItemsLength: 0,
      businessUnitItems: [],
      riskProfileServerItemsLength: 0,
      riskProfileItems: [],
      activityProfileServerItemsLength: 0,
      activityProfileItems: [],
      requireDocumentItems: [],
      requireDocumentItemsLength: 0,
      requireDocumentLoading: false,
      loadedPersonalDocument: false,
      firstNameText: null,
      taxIdText: null,
      contactbranchItems: [],
      contactBranchserverItemsLength: 0,
      dialogBranch: false,
      ContactRegionItems: [],
      ContactRegionSelected: [],
      ContactRegionClientItems: [],
      ContactRegionItemsLength: 0,
      dialogContactRegion: false,
      editedContactRegionItem: {
        region_id: null,
        division_id: null,
        sub_division_id: null
      },
      editedBranchItem: {
        establishment_name: null,
        contact_id: null,
        internal_branch_code: null,
        branch_code: null,
        name: null,
        note: null,
        building: null,
        room_number: null,
        floor: null,
        village: null,
        house_no: null,
        moo_no: null,
        alley: null,
        road: null,
        country_id: null,
        state_id: null,
        city_id: null,
        sub_district_id: null,
        zip_code: null,
        tel: null
      },
      beforeEdit: {},
      divisionUrl: null,
      subDivisionUrl: null,
      contactUrl: null
    }
  },
  created() {
    this.setCityItems()
    this.setPrefixItems()
    this.setTypeDisplayText()
    if (this.$route.name === 'contactCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'contactCreate'
      })
    }
  },
  watch: {
    search(newValue, oldValue) {
      this.serverSide && newValue !== oldValue && this.onServer()
    },
    personalDocItems(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getRequireDocumentItems()
      }
    },
    formData: function (data) {
      if (this.$route.name === 'contactEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'contactEdit',
          text: `${data.first_name} ${data.last_name}`,
          to: {
            name: 'contactEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    },
    'formData.contact_type': function () {
      this.setPrefixItems()
    }
  },
  methods: {
    onContactChanges() {
      this.setPrefixItems()
      this.setTypeDisplayText()
    },
    setPrefixItems() {
      if (
        this.formData.contact_type === null ||
        this.formData.contact_type === undefined
      ) {
        this.formData.contact_type = 'individual'
      }
      this.prefixLoading = true
      this.$api({
        method: 'get',
        url:
          'contact/contact-prefix/?active=true&type=' +
          this.formData.contact_type,
        hideAlert: true
      })
        .then(({ data }) => {
          this.prefixItems = data.results
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.prefixLoading = false
        })
    },
    setTypeDisplayText() {
      if (this.formData.contact_type == 'company') {
        this.firstNameText = this.$t('fields.company')
        this.taxIdText = this.$t('fields.taxID')
      } else {
        this.firstNameText = this.$t('fields.firstName')
        this.taxIdText = this.$t('fields.nationalId')
      }
    },
    prepareData(data) {
      if (this.formData.contact_type == 'company') {
        data.is_employee = false
        data.last_name = ''
        data.date_of_birth = null
      }
      data.name = this.fullName
      data.passport_no = data.national_id_no
      data.prefix_id = data.prefix_id.id
      data.customer_company_id = data.customer_company_id.id
      if (data.role_id !== null && data.role_id !== undefined) {
        data.role_id = data.role_id.id
      }
      return data
    },
    onEditDirectorDoc(item) {
      this.getDirectorItems(item)
      this.dialogDirectorDoc = true
    },
    onEditDirectorTerm(item) {
      this.editedIndex = this.directorTermItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogDirectorTerm = true
    },
    onEditStakeholderDoc(item) {
      this.getStakeholderItems(item)
      this.dialogStakeHolder = true
    },
    onEdit(item) {
      this.editedItemIndex = this.addressItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialog = true
    },
    save() {
      this.loading = true
      this.savedItem.name = this.editedItem.name
      this.savedItem.contact_id = this.$route.params.id
      this.savedItem.street = this.editedItem.street
      this.savedItem.country_id = this.editedItem.country_id.id
      this.savedItem.state_id = this.editedItem.state_id.id
      this.savedItem.city_id = this.editedItem.city_id.id
      this.savedItem.sub_district_id = this.editedItem.sub_district_id.id
      this.savedItem.zip_code = this.editedItem.sub_district_id.zip_code
      this.savedItem.state = 'Approved'

      const data = this.savedItem
      var itemID = ''
      if (this.editId !== null) {
        itemID = this.editId + '/'
      }

      this.$api({
        method: this.apiMethod,
        url: `contact/contact-address/` + itemID,
        data
      })
      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeAddress()
      this.getAddressItems()
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeBusiness() {
      this.dialogBusiness = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeBusinessUnit() {
      this.dialogBusinessUnit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeRiskProfile() {
      this.dialogRiskProfile = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeActivityProfile() {
      this.dialogActivityProfile = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeStakeHolder() {
      this.dialogStakeHolder = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeDirectorDoc() {
      this.dialogDirectorDoc = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeDirectorTerm() {
      this.dialogDirectorTerm = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    closeAddress() {
      this.dialogAddress = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    openDialogBranch() {
      this.dialogBranch = true
      this.apiMethod = 'post'
      this.contactUrl =
        'contact/contact/?active=true&id=' + this.$route.params.id
      this.editedBranchItem = Object.assign({
        contact_id: this.formData
      })
    },
    closeDialogBranch() {
      this.dialogBranch = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `contact/contact-address/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getAddressItems()
        })
      })
      this.closeDelete()
      this.loading = false
    },
    closeDelete() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultDocumentItem)
        this.editedItemIndex = -1
      })
    },
    async getCompanyOtherDocItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        await this.$api({
          method: 'get',
          url: `contact/personal-doc/?contact_ids=${this.$route.params.id}&type=company&document_template_id__type__in=do,as,eq,re,ex,hu,la,ge`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.companyOtherDocItems = data.results
          this.loadedPersonalDocument = true
          this.loading = false
        })
      }
      this.loading = false
    },
    async getCompanyOtherLicenseItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        await this.$api({
          method: 'get',
          url: `contact/personal-doc/?contact_ids=${this.$route.params.id}&document_template_id__type=li&type=company`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.companyOtherLicenseItems = data.results
          this.loadedPersonalDocument = true
          this.loading = false
        })
      }
      this.loading = false
    },

    async getPersonalDocItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        await this.$api({
          method: 'get',
          url: `contact/personal-doc/?contact_ids=${this.$route.params.id}&document_template_id__type=li&type=individual`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.personalDocItems = data.results
          this.loadedPersonalDocument = true
          this.loading = false
        })
      }
      this.loading = false
    },
    getPersonalDocClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    loadDetails(item) {
      this.subPersonalDocItems.push(item)
    },
    getStakeholderDocItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `doc-template/document?name=บอจ.5`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          if (data.count > 0) {
            this.$api({
              method: 'get',
              url: `contact/personal-doc?contact_ids=${this.$route.params.id}&document_template_id=${data.results[0].id}`,
              hideSuccessAlert: true
            }).then(({ data }) => {
              this.serverItemsLength = data.count
              this.stakeholderDocItems = data.results
              this.loading = false
            })
          }
        })
      }
      this.loading = false
    },
    getIndividualStakeholderDocItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `special-doc/stakeheader-doc/?contact_id__id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.individualItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getIndividualStakeholderDocClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    getIndividualDirectorDocItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `doc-template/document?name=หนังสือรับรองบริษัท`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          if (data.count > 0) {
            this.$api({
              method: 'get',
              url: `special-doc/director-doc/?contact_id__id=${this.$route.params.id}`,
              hideSuccessAlert: true
            }).then(({ data }) => {
              this.serverItemsLength = data.count
              this.individualItems = data.results
              this.loading = false
            })
          }
        })
      }
      this.loading = false
    },
    getStakeholderItems(item) {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `special-doc/stakeheader-doc?personal_id=${item.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.stakeholderItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getDirectorItems(item) {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `special-doc/director-doc?personal_id=${item.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.directorItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getDirectorDocItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `doc-template/document?name=หนังสือรับรองบริษัท`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          if (data.count > 0) {
            this.$api({
              method: 'get',
              url: `contact/personal-doc?contact_ids=${this.$route.params.id}&document_template_id=${data.results[0].id}`,
              hideSuccessAlert: true
            }).then(({ data }) => {
              this.serverItemsLength = data.count
              this.directorDocItems = data.results
              this.loading = false
            })
          }
        })
      }
      this.loading = false
    },
    getDirectorTermItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `contact/director-term?contact_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.directorTermItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTaxRegistrationDocItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `doc-template/document?name=ภ.พ.20`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          if (data.count > 0) {
            this.$api({
              method: 'get',
              url: `contact/personal-doc?contact_ids=${this.$route.params.id}&document_template_id=${data.results[0].id}`,
              hideSuccessAlert: true
            }).then(({ data }) => {
              this.serverItemsLength = data.count
              this.taxRegistrationDocItems = data.results
              this.loading = false
            })
          }
        })
      }
      this.loading = false
    },
    getContributorDocItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `doc-template/document?name=บริคณห์สนธิ`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          if (data.count > 0) {
            this.$api({
              method: 'get',
              url: `contact/personal-doc?contact_ids=${this.$route.params.id}&document_template_id=${data.results[0].id}`,
              hideSuccessAlert: true
            }).then(({ data }) => {
              this.serverItemsLength = data.count
              this.contributorDocItems = data.results
              this.loading = false
            })
          }
        })
      }
      this.loading = false
    },
    getAddressItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `contact/contact-address?contact_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.addressItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getAddressClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    getStateItem() {
      var url = ''

      if (
        this.editedItem.country_id !== null &&
        this.editedItem.country_id !== undefined
      ) {
        url = 'contact/resstate?country_id=' + this.editedItem.country_id.id
      } else {
        url = 'contact/resstate/'
      }
      this.stateUrl = url
    },
    getSubdistrict() {
      var url = ''

      if (
        this.editedItem.city_id !== null &&
        this.editedItem.city_id !== undefined
      ) {
        url = 'contact/ressubdistrict?city_id=' + this.editedItem.city_id.id
      } else {
        url = 'contact/ressubdistrict/'
      }

      this.subdistrictUrl = url
    },
    getBusinessItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `contact/business/?contact=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.businessServerItemsLength = data.count
          this.businessItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getBusinessClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    getBusinessUnitItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `contact/contact-business-unit/?contact_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          if (data.results.length > 0) {
            const business_unit_ids = data.results
              ?.map((item) => item.business_unit_id?.id)
              .join(',')
            params = {
              is_primary: true,
              business_unit_id__id__in: business_unit_ids,
              ...options.multiSearch,
              ...this.query
            }
            this.$api({
              method: 'get',
              url: `contact/business-unit-type/`,
              hideSuccessAlert: true,
              params
            }).then(({ data }) => {
              this.businessUnitServerItemsLength = data.count
              this.businessUnitItems = data.results
              this.loading = false
            })
          }
        })
      }
      this.loading = false
    },
    getBusinessUnitClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    getRiskProfileItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `contact/contact-risk-profile/?contact_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.riskProfileServerItemsLength = data.count
          this.riskProfileItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getRiskProfileClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    getActivityProfileItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `contact/contact-activity-profile/?contact_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.activityProfileServerItemsLength = data.count
          this.activityProfileItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getActivityProfileClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    getContactbranchItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `contact/contact-branch?contact_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.contactBranchserverItemsLength = data.count
          this.contactbranchItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getClientContactbranchItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    onDeleteBusinessItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `contact/business/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getBusinessItems()
        })
      })
      this.loading = false
    },
    onDeleteBusinessUnitItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'get',
          url: `contact/contact-business-unit/`,
          params: {
            contact_id: this.$route.params.id,
            business_unit_id__in: items
              .map((value) => value.business_unit_id.id)
              .join(',')
          },
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.$api({
            method: 'delete',
            url: `contact/contact-business-unit/`,
            data: {
              pks: data.results.map((value) => value.id)
            }
          })
            .then(() => {
              this.selected = []
              this.getBusinessUnitItems()
            })
            .catch((error) => {
              console.error('Error deleting business units:', error)
            })
        })
      })
      this.loading = false
    },
    onDeleteRiskProfileItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `contact/contact-risk-profile/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRiskProfileItems()
        })
      })
      this.loading = false
    },
    onDeleteActivityProfileItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `contact/contact-activity-profile/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getActivityProfileItems()
        })
      })
      this.loading = false
    },
    onDeleteDirectorTermItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `contact/director-term/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getDirectorTermItems()
        })
      })
      this.loading = false
    },
    onDeleteContectBranchItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `contact/contact-branch/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getContactbranchItems()
        })
      })
      this.loading = false
    },
    async saveBusiness() {
      if (
        this.editedItem.industry_id !== null &&
        this.editedItem.industry_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.contact = this.$route.params.id
        data.industry_id = this.editedItem.industry_id.id
        if (
          this.editedItem.business_cate_id !== null &&
          this.editedItem.business_cate_id !== undefined
        ) {
          data.business_cate_id = this.editedItem.business_cate_id.id
        }
        if (
          this.editedItem.business_type_id !== null &&
          this.editedItem.business_type_id !== undefined
        ) {
          data.business_type_id = this.editedItem.business_type_id.id
        }
        await this.$api({
          method: 'post',
          url: `contact/business/`,
          data
        })
        this.loading = false
        this.editedItem = {}
        this.closeBusiness()
        this.getBusinessItems()
      }
    },
    async saveBusinessUnit() {
      if (
        this.editedItem.businessUnit_id !== null &&
        this.editedItem.businessUnit_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.contact_id = this.$route.params.id
        data.business_unit_id = this.editedItem.businessUnit_id.id

        await this.$api({
          method: 'post',
          url: `contact/contact-business-unit/`,
          data
        })
        this.loading = false
        this.editedItem = {}
        this.closeBusinessUnit()
        this.getBusinessUnitItems()
      }
    },
    async saveRiskProfile() {
      if (
        this.editedItem.risk_profile_template_header_id !== null &&
        this.editedItem.risk_profile_template_header_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.contact_id = this.$route.params.id
        data.risk_profile_template_header_id =
          this.editedItem.risk_profile_template_header_id.id

        await this.$api({
          method: 'post',
          url: `contact/contact-risk-profile/`,
          data
        })
        this.loading = false
        this.editedItem = {}
        this.closeRiskProfile()
        this.getRiskProfileItems()
      }
    },
    async saveActivityProfile() {
      if (
        this.editedItem.activity_profile_id !== null &&
        this.editedItem.activity_profile_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.contact_id = this.$route.params.id
        data.activity_profile_id = this.editedItem.activity_profile_id.id

        await this.$api({
          method: 'post',
          url: `contact/contact-activity-profile/`,
          data
        })
        this.loading = false
        this.editedItem = {}
        this.closeActivityProfile()
        this.getActivityProfileItems()
      }
    },
    validateTerm() {
      const dataList = [...this.directorTermItems, this.editedItem]
      dataList.sort(
        (a, b) => new Date(a.start_term_date) - new Date(b.start_term_date)
      )

      for (let i = 1; i < dataList.length; i++) {
        const prevEnddate = new Date(dataList[i - 1].end_term_date)
        const currStartdate = new Date(dataList[i].start_term_date)
        if (currStartdate < new Date(prevEnddate.getTime() + 86400000)) {
          dataList[i - 1].end_term_date = new Date(
            currStartdate.getTime() - 86400000
          ).toISOString()
        }
      }
      return false
    },
    saveTerm() {
      // if (this.validateTerm()) {
      this.loading = true
      if (this.editedItem.term !== null && this.editedItem.term !== undefined) {
        this.savedItem.term = this.editedItem.term
      }
      this.savedItem.contact_id = this.$route.params.id
      this.savedItem.start_term_date = this.editedItem.start_term_date
      this.savedItem.end_term_date = this.editedItem.end_term_date
      this.savedItem.image = this.editedItem.image

      const data = this.savedItem
      var itemID = ''
      if (this.editId !== null) {
        itemID = this.editId + '/'
      }
      this.$api({
        method: this.apiMethod,
        url: `contact/director-term/` + itemID,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: objFormData(data)
      })
      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeDirectorTerm()
      this.getDirectorTermItems()
      // }
    },
    async getRequireDocumentItems() {
      this.requireDocumentLoading = true
      if (this.businessItems.length) {
        const ids = this.businessItems
          .map((v) => v.business_type_id.id)
          .join(',')
        if (!this.loadedPersonalDocument) {
          await this.getPersonalDocItems()
        }
        this.$api({
          method: 'get',
          url: `doc-template/doc-industry/?business_type_id__in=${ids}&document_template_id__contact_type=${this.formData.contact_type}&document_template_id__active=true`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.requireDocumentItemsLength = data.count
          this.requireDocumentItems = data.results.map((v) => ({
            document: v.document_template_id.name,
            done: this.personalDocItems.find(
              (d) => d.document_template_id.id === v.document_template_id.id
            )
          }))
          this.requireDocumentLoading = false
        })
      }
      this.requireDocumentLoading = false
    },
    async saveContactBranch() {
      this.loading = true

      const data = { ...this.editedBranchItem }
      const check_same_value = ['branch_code', 'internal_branch_code', 'name']
      check_same_value.forEach((key) => {
        if (data[key] === this.beforeEdit[key]) {
          delete data[key]
        }
      })
      data.contact_id = this.$route.params.id
      data.country_id = this.editedBranchItem.country_id.id
      data.state_id = this.editedBranchItem.state_id.id
      data.city_id = this.editedBranchItem.city_id.id
      data.sub_district_id = this.editedBranchItem.sub_district_id.id

      await this.$api({
        method: this.apiMethod,
        url: `contact/contact-branch/${
          this.editId && this.apiMethod == 'put' ? this.editId + '/' : ''
        }`,
        data
      })
      this.loading = false
      this.editedBranchItem = {}
      this.closeDialogBranch()
      this.getContactbranchItems()
    },
    getContactRegionItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        const ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          contact_id: this.$route.params.id
        }
      } else {
        this.params = { contact_id: this.$route.params.id }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'contact/contact-region/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.ContactRegionItemsLength = data.count
          this.ContactRegionItems = data.results
          this.ContactRegionClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getContactRegionClientItems(multiSearch) {
      this.ContactRegionClientItems = this.ContactRegionItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    closeContactRegion() {
      this.editedContactRegionItem = {}
      this.dialogContactRegion = false
    },
    async saveContactRegion() {
      this.loading = true
      const data = {}
      data.contact_id = this.$route.params.id
      if (
        this.editedContactRegionItem.region_id !== null &&
        this.editedContactRegionItem.region_id !== undefined
      ) {
        data.region_id = this.editedContactRegionItem.region_id.id
        if (
          this.editedContactRegionItem.division_id !== null &&
          this.editedContactRegionItem.division_id !== undefined
        ) {
          data.division_id = this.editedContactRegionItem.division_id.id
        }
        if (
          this.editedContactRegionItem.sub_division_id !== null &&
          this.editedContactRegionItem.sub_division_id !== undefined
        ) {
          data.sub_division_id = this.editedContactRegionItem.sub_division_id.id
        }
      }
      await this.$api({
        method: this.apiMethod,
        url: `contact/contact-region/${this.editId ? this.editId + '/' : ''}`,
        data
      })
      this.loading = false
      this.editedContactRegionItem = {}
      this.closeContactRegion()
      this.getContactRegionItems()
    },
    onEditContactRegion(item) {
      this.editedContactRegionItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogContactRegion = true
    },
    onEditBranchItem(item) {
      this.beforeEdit.branch_code = item.branch_code
      this.beforeEdit.internal_branch_code = item.internal_branch_code
      this.beforeEdit.name = item.name
      this.editedBranchItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogBranch = true
    },
    onDeleteContactRegionItem(item) {
      this.onDeleteContactRegion(item)
    },
    onDeleteContactRegion(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `contact/contact-region/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    getDivisionItem() {
      var url = ''
      if (
        this.editedContactRegionItem.region_id !== null &&
        this.editedContactRegionItem.region_id !== undefined
      ) {
        url =
          'region/division/?active=true&state=approved&region_id=' +
          this.editedContactRegionItem.region_id.id
      } else {
        url = 'region/division/?active=true&state=approved'
      }
      this.divisionUrl = url
    },
    getSubDivisionItem() {
      var url = ''
      if (
        this.editedContactRegionItem.division_id !== null &&
        this.editedContactRegionItem.division_id !== undefined
      ) {
        url =
          'region/sub-division/?active=true&state=approved&division_id=' +
          this.editedContactRegionItem.division_id.id +
          '&region_id=' +
          this.editedContactRegionItem.region_id.id
      } else {
        url = 'region/sub-division/?active=true&state=approved'
      }
      this.subDivisionUrl = url
    },
    setCityItems() {
      this.cityLoading = true
      this.$api({
        method: 'get',
        url:
          'contact/rescity?state_id=' +
          (this.editedBranchItem.state_id
            ? this.editedBranchItem.state_id.id
            : ''),
        hideAlert: true
      })
        .then(({ data }) => {
          this.cityItems = data.results
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.cityLoading = false
        })
    }
  }
}
</script>
